import { Restful } from "./profile.service.js";

// Config Swal
let Alert = function(status, msg, thisVue) {
    thisVue.$swal({
        position: "top",
        type: status,
        title: msg,
        showConfirmButton: false,
        timer: 2500
    });
};

export default {
    data() {
        return {
            activeContent: "profile",
            activeTab: "",
            listAffiliate: [],
            listInvoice: [],
            listDiscount: [],
            listPage: [],
            listUpgrade: [],
            listPlan: [],
            listPlanUnlimited: [],
            listUnlimitUpgrade: [],
            newRefValue: "",
            oldPwd: "",
            newPwd: "",
            affiliate: {},
            isShowLogout: false,
            userInfo: {
                id: "",
                email: "",
                point: "",
                role: "",
                firstName: "",
                lastName: "",
                package_type: "",
                reach: ''
            },
            transferId: "",
            pageId: "",
            packageId: "",
            planId: "",
            fbName: ""
        };
    },
    computed: {
        computeNameUser() {
            if (this.fbName) {
                return this.fbName;
            }
            return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
        },
        computeNameAppSelect() {
            if (!this.listPage || !this.pageId) return "Select app";
            return this.listPage.find((item) => item.id === this.pageId).name;
        },
        computeNamePackageSelect() {
            if (!this.listUpgrade || !this.packageId) return "Select package";
            return this.listUpgrade.find((item) => item.id === this.packageId).package_name;
        }
    },
    methods: {
        changeActiveContent(content) {
            // Xử lí click sidebar và call data
            if (content !== this.activeContent) {
                this.activeTab = "";
            }

            this.activeContent = content;
            if (content === "affiliate") {
                this.getAffiliate();
                return;
            }
            if (content === "invoice") {
                this.getInvoice();
                return;
            }
            this.getDiscount;
        },
        getUserInfo() {
            let thisVue = this;
            Restful.getUserInfo(function(res) {
                if (res && res.code === 200 && res.data) {
                    console.log(res.data);
                    let { id, reach, point, first_name, last_name, email, role, avatar, package_type } = res.data;
                    thisVue.userInfo.id = id;
                    thisVue.userInfo.email = email;
                    thisVue.userInfo.point = point || 0;
                    thisVue.userInfo.reach = reach || 0;
                    thisVue.userInfo.firstName = first_name;
                    thisVue.userInfo.lastName = last_name;
                    thisVue.userInfo.role = role;
                    thisVue.userInfo.avatar = avatar;
                    thisVue.userInfo.package_type = package_type || "Free";
                }
            });
        },
        getAffiliate() {
            let thisVue = this;
            Restful.postAffiliate(JSON.stringify({ limit: 40, skip: 0 }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listAffiliate = res.data;
                }
            });
        },
        getInvoice() {
            let thisVue = this;
            Restful.postInvoice(JSON.stringify({ limit: 40, skip: 0 }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listInvoice = res.data;
                }
            });
        },
        getDiscount() {
            let thisVue = this;
            Restful.postInvoice(JSON.stringify({ type: "commission", limit: 40, skip: 0 }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listDiscount = res.data;
                }
            });
        },
        updateRef() {
            let thisVue = this;

            if (!thisVue.newRefValue) return;

            Restful.postUpdateRef(
                JSON.stringify({ affiliate_id: thisVue.affiliate.id, ref: thisVue.newRefValue }),
                function(res) {
                    if (res && res.code === 200 && res.data) {
                        thisVue.newRefValue = "";
                        thisVue.listAffiliate = res.data;

                        Alert("success", "Update success", thisVue);
                        return;
                    }

                    Alert("error", "Update fail", thisVue);
                }
            );
        },
        transferApp() {
            let thisVue = this;

            if (!thisVue.transferId || !thisVue.pageId) return;
            Restful.transferApp(JSON.stringify({ page_id: thisVue.pageId, new_owner: thisVue.transferId }), function(
                res
            ) {
                if (res && res.code === 200 && res.data) {
                    thisVue.transferId = "";
                    thisVue.pageId = "";

                    thisVue.getPage();
                    Alert("success", "Transfer app success", thisVue);
                    return;
                }
                Alert("error", "Transfer app fail", thisVue);
            });
        },
        getPage() {
            let thisVue = this;

            Restful.getPage(function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listPage = res.data;
                }
            });
        },
        getListUpgrade() {
            let thisVue = this;

            Restful.getListUpgrade(function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listUpgrade = res.data;
                }
            });
        },
        upgradeApp() {
            let thisVue = this;

            Restful.postUpgradeApp(JSON.stringify({ page: thisVue.pageId, upgrade: thisVue.packageId }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.pageId = "";
                    thisVue.packageId = "";

                    Alert("success", "Upgrade app success", thisVue);
                    return;
                }
                Alert("error", "Upgrade app fail", thisVue);
            });
        },
        getListPlan() {
            let thisVue = this;

            Restful.getListPlan(function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.listPlan = res.data;
                }
            });
        },
        createInvoice() {
            let thisVue = this;

            Restful.postCreateInvoice(JSON.stringify({ package: thisVue.planId }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.planId = "";
                    thisVue.getInvoice();

                    Alert("success", "Create success", thisVue);
                    return;
                }
                Alert("error", "Create fail", thisVue);
            });
        },
        activeInvoice(item) {
            let thisVue = this;

            Restful.postActiveInvoice(JSON.stringify({ invoice: item.id }), function(res) {
                if (res && res.code === 200 && res.data) {
                    thisVue.getInvoice();

                    Alert("success", "Active success", thisVue);
                    return;
                }
                if (res && res.data && res.data.message) {
                    Alert("error", res.data.message, thisVue);
                    return;
                }
                Alert("error", "Active fail", thisVue);
            });
        },
        updateUser() {
            let thisVue = this;

            let newName = $("#profile-component .profile-tab .name-input").val();
            if (!newName) return;
            let newNameArr = newName.split(" ");
            let newFirstName = newNameArr[0];
            let newLastName = newNameArr.slice(1).join(" ");
            if (!newFirstName || !newLastName) return;

            Restful.updateUser(JSON.stringify({ first_name: newFirstName, last_name: newLastName }), function(res) {
                if (res && res.code === 200 && res.data) {
                    Alert("success", "Update success", thisVue);
                    thisVue.getUserInfo();

                    newFirstName = "";
                    newLastName = "";

                    return;
                }
                Alert("error", "Update fail", thisVue);
            });
        },
        updatePwd() {
            let thisVue = this;

            if (!this.oldPwd || !this.newPwd) {
                Alert("warning", "Please enter infomation", thisVue);
                return;
            }

            Restful.updatePwd(
                JSON.stringify({
                    email: thisVue.userInfo.email,
                    old_password: thisVue.oldPwd,
                    new_password: thisVue.newPwd
                }),
                function(res) {
                    if (res && res.code === 200 && res.data) {
                        Alert("success", "Update success", thisVue);

                        thisVue.oldPwd = "";
                        thisVue.newPwd = "";

                        return;
                    }
                    if (res && res.data && res.data.message) {
                        Alert("error", res.data.message, thisVue);
                        return;
                    }
                    Alert("error", "Update fail", thisVue);
                }
            );
        },
        toggleSection(type, val) {
            switch (type) {
                case "popup-action":
                    $("#profile-component .overlay").show();
                    $("#profile-component .popup-action").show();
                    break;
                case "list-app":
                    $("#profile-component .overlay").show();
                    $("#profile-component .list-app-dropdown .list-option").toggle();
                    if (val) {
                        this.pageId = val;
                        $("#profile-component .overlay").hide();
                    }
                    break;
                case "create-plans":
                    $("#profile-component .overlay").show();
                    $("#profile-component .create-dropdown .list-option").toggle();
                    if (val) {
                        this.planId = val;
                        $("#profile-component .overlay").hide();
                    }
                    break;
                case "package":
                    $("#profile-component .overlay").show();
                    $("#profile-component .package-dropdown .list-option").toggle();
                    if (val) {
                        this.packageId = val;
                        $("#profile-component .overlay").hide();
                    }
                    break;
                case "close-all":
                    $("#profile-component .dropdown-pattern .list-option").hide();
                    $("#profile-component .popup-action").hide();
                    $("#profile-component .overlay").hide();
                    break;
            }
        },
        getUnlimited: function() {
            var thisVue = this;
            Restful.getPlansUnlimited(JSON.stringify({}), function(data) {
                if (data && data.code == 200) {
                    thisVue.listPlanUnlimited = data.data;
                }
                console.log(data);
            })
        },
        createUnlimited: function() {
            var thisVue = this;
            var totalCreate = this.getUnlimitCreate();
            if (totalCreate < 6) {
                Restful.createPlanUnlimit(JSON.stringify({
                    payment_method: "MANUAL",
                    package: thisVue.planId,
                    description: "",
                    app_id: thisVue.pageId
                }), function(data) {
                    if (data && data.code == 200) {
                        Alert("success", "Create success", thisVue);
                        thisVue.listUnlimitUpgrade.unshift(data.data);
                        thisVue.changeActiveContent('invoice');
                    }
                })
            } else {
                Alert("warning", "Bạn có quá nhiều giao dịch chưa xử lý!", thisVue);
                thisVue.changeActiveContent('invoice');
            }
            
        },
        getReachPlans: function() {
            var thisVue = this;
            Restful.getReachPlans(JSON.stringify({
                limit: 100,
                skip: 0
            }), function(data) {
                if (data && data.code == 200) {
                    thisVue.listUnlimitUpgrade = data.data;
                }
            })
        },
        getUnlimitCreate: function() {
            var dem = 0;
            var thisVue = this;
            if (thisVue.listUnlimitUpgrade && thisVue.listUnlimitUpgrade.length > 0) {
                thisVue.listUnlimitUpgrade.find(function(x) {
                    if (x.status == "CREATED") {
                        dem++;
                    }
                });
            }
            return dem;
        }
    },
    created() {
        this.getUserInfo();
        this.getAffiliate();
        //this.getInvoice();
        this.getDiscount();
        this.getPage();
        //this.getListUpgrade();
        this.getListPlan();
        this.getUnlimited();
        this.getReachPlans();
    }
};
