import resful from "../../services/resful.js";
import API from "../../services/api/methods.js";

export const Restful = {

	//Lấy thông tin User
	getUserInfo: async function(next) {
		try {
			let res = await resful.get('/app/me', {});
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Lấy Affiliate
	postAffiliate: async function(data, next) {
		try {
			let res = await resful.post('/app/affiliate', data);
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Update Ref với affiliate_id và new ref value
	postUpdateRef: async function(data, next) {
		try {
			let res = await resful.post('/app/update-affiliate-ref', data);
			next(res);
		} catch (e) {
			console.log(e);
		}
	},
	//Lấy danh sách Invoice
	postInvoice: async function(data, next) {
		try {
			let res = await resful.post('/app/invoices', data);
			next(res);
		} catch (e) {
			console.log(e);
		}
	},
	//Lấy danh sách Discount
	postDiscount: async function(data, next) {
		try {
			let res = await resful.post('/app/invoices', data);
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Đăng nhập với email và password
	signIn: async function(data, next) {
		try {
			let res = await resful.post('/auth/sign-in', data);
			next(res);
		} catch (e) {
			next(e)
			console.log(e);
		}
	},
	//Đăng kí với first-name, last_name, email và password
	signUp: async function(data, next) {
		try {
			let res = await resful.post('/auth/sign-up', data);
			next(res);
		} catch (e) {
			next(e)
			console.log(e);
		}
	},
	// Đăng nhập Fb
	signInFb: async function(data, next) {
		try {
			let res = await resful.post('/auth/sign-in-facebook', data);
			next(res);
		} catch (e) {
			next(e)
			console.log(e);
		}
	},
	//Quên mật khẩu với email
	forgotPwd: async function(data, next) {
		try {
			let res = await resful.post('/auth/forgot-password', data);
			next(res);
		} catch (e) {
			next(e);
			console.log(e);
		}
	},
	//Transfer app với new_owner và page_id
	transferApp: async function(data, next) {
		try {
			let res = await resful.post('/app/transfer-app', data);
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Lấy danh sách các App
	getPage: async function(next) {
		try {
			let res = await resful.get('/app/page', {});
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Lấy danh sách các package
	getListUpgrade: async function(next) {
		try {
			let res = await resful.get('/app/upgrades', {});
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Upgrade app với page và upgrade
	postUpgradeApp: async function(data, next) {
		try {
			let res = await resful.post('/app/upgrade-app', data);
			next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Lấy danh sách các Plan
	getListPlan: async function(next) {
		try {
			let res = await resful.get('/app/plans');
        	next(res);
		} catch (e) {
			console.log(e)
		}
	},
	//Tạo Invoice với package
	postCreateInvoice: async function(data, next) {
		try {
			let res = await resful.post('/app/create-invoice', data)
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	//Active Invoice với invoice
	postActiveInvoice: async function(data, next) {
		try {
			let res = await resful.post('/app/active-invoice', data)
			next(res)
		} catch (e) {
			console.log(e)
		}
	},
	loginGG: async function(data, next) {
		try {
    		let res = await resful.post("/ggsheets/google/oauthgooglesheets", data);
    		next(res);
		} catch (e) {
			next(e)
			console.log(e)
		}
	},
	getFbInfo: async function(id, accessToken, next) {
		try {
			API.get(`https://graph.facebook.com/${id}`, { access_token: accessToken}, next)
		} catch (e) {
			console.log(e)
		}
	},
	updateUser: async function(data, next) {
		try {
			let res = await resful.post('/app/me', data)
			next(res);
		} catch(e) {
			console.log(e)
		}
	},
	updatePwd: async function(data, next) {
		try {
			let res = await resful.post('/auth/update-password', data)
			next(res);
		} catch(e) {
			console.log(e)
		}
	},
	//lấy danh sách gói
	getPlansUnlimited: async function(data, next) {
		try {
			let res = await resful.post("/payment/plans", data);
			next(res);
		} catch(e) {
			console.log(e);
		}
	},
	createPlanUnlimit: async function(data, next) {
		try {
			let res = await resful.post("/payment/create-transaction", data);
			next(res);
		} catch(e) {
			console.log(e);
		}
	},
	getReachPlans: async function(data, next) {
		try {
			let res = await resful.post("/payment/read-transaction", data);
			next(res);
		} catch(e) {
			console.log(e);
		}
	}
}
